<template>
  <div class="th-wrplist">
    <div class="th-wrplist-exist" v-if="navbarListArrayProp.length > 0">
      <ul v-for="(item, index) in navbarListArrayProp"
          :key="index"
          class="list-group th-wrplist-exist-list"
      >
        <li class="list-group-item th-wrplist-exist-list--item"
            v-bind:class="{'disabled': !isPrivacyAccepted}">
          <b-link :to="item.routePath">
            <b-icon :icon="item.iconName"></b-icon>
            <p>
              {{ $t(`${item.listName}`, currentSelectedLanguage) }}
            </p>
          </b-link>
        </li>
      </ul>
    </div>
    <p class="th-wrplist-null" v-else>
      {{ $t('Include array error', currentSelectedLanguage) }}
    </p>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'navbar-list-group',
  props: {
    navbarListArrayProp: {
      type: Array,
      default: () => [{
        routePath: '/dashboard',
        listName: 'HomeNavbarTranslate',
        iconName: 'house-door',
      }, {
        routePath: '/patients',
        listName: 'PatientsNavbarTranslate',
        iconName: 'person',
      }],
    },
  },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters({
      isPrivacyAccepted: 'getIsPrivacyAccepted',
    }),
  },
};
</script>

<style lang="scss" scoped>
.th-wrplist {
  &-exist {
    &-list {
      @include border-radius(0);

      &--item {
        background-color: $transparent;
        border: $none;
        @include space($type: padding, $direction: top, $amount: 0);
        @include space($type: padding, $direction: left, $amount: 0);
        @include space($type: padding, $direction: bottom, $amount: 22px);

        & > a {
          color: $white;
          text-decoration: $none;
          display: flex;
          flex-direction: row;

          & > svg {
            font-size: 23px;
          }

          & > p {
            @include space($type: margin, $direction: left, $amount: 16px);
            @include space($type: margin, $direction: bottom, $amount: 0);
            @include font-source($openSans, 18px, $white, 600, normal, normal);
          }
        }

        &.disabled {
          & > a {
            color: #6c757d;

            p {
              color: #6c757d;
            }
          }
        }
      }
    }
  }

  &-null {
    @include font-source($openSans, 11px, $white, normal, 1.55px, normal);
    color: $white;
  }
}
</style>
