<template>
  <div v-if="pageReady">
    <div class="th-dash">
      <sidebar-element class="th-dash--item"/>
      <error-popup/>
      <div class="th-dash--item">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import sidebarElement from '../../components/moleculs/sidebarNavigation.vue';
import {GET_USER_DEATILS} from '../../store/modules/profile.module';
import ErrorPopup from "../../components/moleculs/errorPopup";

export default {
  name: 'dashboard-layout',
  components: {ErrorPopup, sidebarElement},
  computed: {
    ...mapGetters({
      isPrivacyAccepted: 'getIsPrivacyAccepted',
    }),
  },
  data() {
    return {
      pageReady: false,
    };
  },
  async beforeMount() {
    await this.$store.dispatch(GET_USER_DEATILS);
    if (!this.isPrivacyAccepted && this.$route.name !== 'disclaimerPage') {
      await this.$router.push({name: 'disclaimerPage'});
    } else if (this.isPrivacyAccepted && this.$route.name === 'disclaimerPage') {
      await this.$router.push({name: 'Dashboard'});
    }
    this.pageReady = true;
  },
};
</script>

<style lang="scss" scoped>
.th-dash {
  display: flex;
  flex-direction: row;
  height: 100%;

  &--item {
    &:first-child {
      flex-basis: 20%;
      display: flex;
      flex-direction: column;
    }

    &:last-child {
      flex: 1;
      background-color: $jessy-jay;
    }
  }
}
</style>
