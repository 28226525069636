<template>
  <div class="th-side">
    <modal-elem/>
    <div class="th-side-wrp--image">
      <img src="../../assets/login-logo.svg" />
      <p class="text-center th-side-wrp--image--clinic">
        {{ $t('Clinic Dashboard', currentSelectedLanguage) }}
      </p>
    </div>
    <navbar-list />
    <div class="th-side-wrp--footer">
      <p v-if="userProfile" class="text-center">
        {{ userProfile.Email }}
      </p>
      <custom-btn
        @buttonEventEmit="logoutUser"
        :buttonText="$t('logout', currentSelectedLanguage)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import modalElem from './universalModal.vue';
import navbarList from '../atoms/navbar-list-group.vue';
import customBtn from '../atoms/button.vue';
import { LOGOUT } from '../../store/modules/auth.module';

export default {
  name: 'sidebarNavigation',
  components: {
    navbarList,
    customBtn,
    modalElem,
  },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters({
      userProfile: 'getProfile',
      modalData: 'getModal',
    }),
  },
  watch: {
    '$route': {
      handler: function(route) {
        if(route.params.imageId !== 'undefined') {
          this.photoIdObject = route.params.imageId
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      photoIdObject: null,
    };
  },
  methods: {
    async logoutUser() {
      await this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: 'Login' })
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.th-side {
  background-color: $primary;
  @include space($type:padding, $direction:top, $amount:36px);
  @include space($type:padding, $direction:right, $amount:29px);
  @include space($type:padding, $direction:bottom, $amount:34px);
  @include space($type:padding, $direction:left, $amount:29px);

  &-wrp--image {
    height: 24px;
    @include space($type:margin, $direction:bottom, $amount:37px);

    img {
      width: 100%;
      height: 100%;
      @include space($type:margin, $direction:bottom, $amount:6px);
    }

    &--clinic {
      @include font-source($openSans, 8.5px, $white, bold, normal, normal);
      text-transform: uppercase;
    }
  }

  &-wrp--footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    & > button {
      @include font-source($openSans, 14px, $white, bold, 1.21px, normal);
    }

    & > p {
      margin-bottom: 20px;
      opacity: 0.3;
      @include font-source($openSans, 12px, $white, normal, normal, normal);
    }
  }
}
</style>
